/**
 * deprecated, replaced with ko-html-to
 * @type {{update: ko.bindingHandlers.textarea.update}}
 */
ko.bindingHandlers.textarea = {
	update: function (element, valueAccessor, allBindingsAccessor) {
		var value = ko.utils.unwrapObservable(valueAccessor());
		if (value) {
			$(element).val( value.replace(/<br\s*[\/]?>/gi, "\n") );
		}
	}
};
