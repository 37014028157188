function createAccessor(value) {
    return function () { return value; };
}
ko.bindingHandlers.src = {
    update: function (element, valueAccessor) {
        var value = ko.unwrap(valueAccessor());

        if (element.src !== value)
            element.setAttribute("src", value);
    }
};
ko.bindingHandlers.href = {
    update: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        var value = ko.unwrap(valueAccessor());
        ko.bindingHandlers.attr.update(element, createAccessor({href: value}), allBindingsAccessor, viewModel, bindingContext);
    }
};

ko.bindingHandlers.whatsapp = {
    update: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        var options = ko.unwrap(valueAccessor()),
            text = ko.unwrap(options.text),
            url = ko.unwrap(options.url);
        var value = "https://api.whatsapp.com/send?text=" + encodeURIComponent(text) + ' ' + url;
        ko.bindingHandlers.attr.update(element, createAccessor({href: value}), allBindingsAccessor, viewModel, bindingContext);
    }
};

ko.bindingHandlers.sms = {
    update: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        var options = ko.unwrap(valueAccessor()),
            text = ko.unwrap(options.text),
            url = ko.unwrap(options.url);
        var value = "sms:?body=" + encodeURIComponent(text) + ' ' + url;
        ko.bindingHandlers.attr.update(element, createAccessor({href: value}), allBindingsAccessor, viewModel, bindingContext);
    }
};

ko.bindingHandlers.mailto = {
    update: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        var email = ko.unwrap(valueAccessor());
        ko.bindingHandlers.attr.update(element, createAccessor({href:'mailto:'+ email}), allBindingsAccessor, viewModel, bindingContext);
        ko.utils.setTextContent(element, email);
    }
};

ko.bindingHandlers.tel = {
    update: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        var tel = ko.unwrap(valueAccessor());
        ko.bindingHandlers.attr.update(element, createAccessor({href:'tel:'+ tel}), allBindingsAccessor, viewModel, bindingContext);
        ko.utils.setTextContent(element, tel);
    }
};