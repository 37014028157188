/**
 * <input type="text" data-bind="valueField: SelectedEmployee, typeahead: TypeAheadUrl, criteria: Criteria" />
 *   this.Criteria = {Departments: ['FIN', 'HR', 'COM']};
 *   this.TypeAheadUrl = 'https://local.getdata.com';
 *   this.SelectedEmployee = ko.observable('');
 */
ko.bindingHandlers.typeahead = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        var $element = $(element);
        var allBindings = allBindingsAccessor();
        var url = ko.unwrap(valueAccessor());
        var criteria = allBindings.criteria;

        var typeaheadOpts = {
            name: 'employees',
            source: function(query, sync, async) {

                $.ajaxSetup({ timeout: 300000 });
                criteria.SearchText = query;
                var data = JSON.stringify(criteria);
                // call ajax to service
                $.ajax({
                    type: "POST", //GET or POST or PUT or DELETE verb
                    url: url, // locates of the wcf service method
                    contentType: 'application/json',
                    crossDomain: true,
                    data: data,
                    cache: false,
                    success: function (data, statusText, xhr) {
                        data = data.map(function (item) {
                            item.textValue = item.text + " (" + item.value + ")";
                            return item;
                        });
                        async(data);
                    },
                    error: function (xhr, status) {
                        console.log(xhr.error);
                        console.log(status);
                    }
                });
            },
            displayKey: 'textValue'
        };

        if (allBindings.typeaheadOptions) {
            $.each(allBindings.typeaheadOptions, function (optionName, optionValue) {
                typeaheadOpts[optionName] = koObject.utils.unwrapObservable(optionValue);
            });
        }

        $element.attr("autocomplete", "off").typeahead({
            hint: true,
            highlight: true,
            minLength: 1
        }, typeaheadOpts)
            .on('typeahead:selected', function (el, datum) {
                console.dir(datum);
                allBindings.valueField(datum.value);
            });
    }
};