/**
 *https://select2.org
 *
 * @type {{init: ko.bindingHandlers.select2.init, update: ko.bindingHandlers.select2.update}}
 */

/*
<select multiple="multiple" data-bind="select2: { data: allOptions() }, selectedOptions: selectedPeople">
    <!-- ko if: allOptions().length === 0 -->
    <option disabled="disabled">No people found</option>
    <!-- /ko -->
</select>
*/

ko.bindingHandlers.select2 = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        var options = ko.unwrap(valueAccessor());
        ko.unwrap(allBindings.get('selectedOptions'));

        $(element).select2(options);
        console.log('init');
    },
    update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        var options = ko.unwrap(valueAccessor());
        ko.unwrap(allBindings.get('selectedOptions'));

        $(element).select2(options);
        console.log('update');
    }
};