/**
 * Custom binding on eternicode datepicker.
 * @see https://github.com/uxsolutions/bootstrap-datepicker
 *
 */
ko.bindingHandlers.datepicker = {
  init: function (element, valueAccessor, allBindingsAccessor) {
    var configInit = {}
    configInit.todayHighlight = false
    configInit.todayBtn = false
    configInit.format = 'DD/MM/YYYY'
    configInit.parse = false
    configInit.language = 'it'
    configInit.autoclose = true
    configInit.startDate = new Date()

    var config = allBindingsAccessor().datepickerOptions || {}

    var options = $.extend({}, configInit, config)

    $(element).datepicker(options)

    ko.utils.registerEventHandler(element, 'changeDate', function (event) {
      var value = valueAccessor()

      if (ko.isObservable(value)) {
        // Only update value when event.date is filled.
        var date = event.date ? event.date : value()
        if (options.parse)
          value(moment(date).format('DD-MM-YYYY'))
        else
          value(date)
      }
    })

    ko.utils.registerEventHandler(element, 'show', function (event) {
      var value = valueAccessor()
    })

  },
  update: function (element, valueAccessor, allBindingsAccessor) {

    var widget = $(element).data('datepicker')

    if (widget) {
      var value = ko.utils.unwrapObservable(valueAccessor())

      if (value) {
        widget.update(value)
      } else {
        widget.fill()
      }
    }
  }
}