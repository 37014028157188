/**
 * new version replacing ko-textarea
 * @type {{init: ko.bindingHandlers.htmlToTextValue.init}}
 */
ko.bindingHandlers.htmlToTextValue = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel, context) {
        var interceptor = ko.computed({
            read: function () {
                var valueObservable = valueAccessor();
                var value = valueObservable();
                return String(value)
                    .replace(/<br>/gi, "\n")
                    .replace(/&gt;/gi, '>')
                    .replace(/&lt;/gi, '<')
                    .replace(/&#39;/gi, "'")
                    .replace(/&quot;/gi, '"')
                    .replace(/<p>/gi, '')
                    .replace(/<\/p>/gi, '')
                    .replace(/&amp;/gi, '&');
            },
            write: function (value) {
                var valueObservable = valueAccessor();
                var htmlValue = String(value)/*
                    .replace(/&/gi, '&amp;')
                    .replace(/"/gi, '&quot;')
                    .replace(/'/gi, '&#39;')
                    .replace(/</gi, '&lt;')
                    .replace(/>/gi, '&gt;')
                 .replace(/\n/gi, "<br>")*/;
                valueObservable(htmlValue);
            }
        });

        ko.applyBindingsToNode(element, {value: interceptor}, context);
    }
};