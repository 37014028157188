/*
 <gusty params="loading:loading,text: text"></gusty>
*/
ko.components.register('gusty', {
    viewModel: function (params) {
        var self = this;
        self.loading = params.loading;
        self.text = params.text;
    },
    template:
        '<div data-bind="visible: loading">' +
        '<div id="gusty-spinner" class="text-center">' +
        '    <div id="gusty-loader"></div>' +
        '    </div>' +
        '    <div class="text-primary text-center text-sm w500"><span data-bind="text:text"></span>' +
        '</div>' +
        '</div>'
});

