/**
 *<span data-bind="trimText: text,trimLength:24"></span>
 * @type {{}}
 */
ko.bindingHandlers.trimLength = {}
ko.bindingHandlers.trimText = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel) {
        var trimmedText = ko.computed(function () {
            var untrimmedText = ko.utils.unwrapObservable(valueAccessor())
            var defaultMaxLength = 60
            var minLength = 5
            var maxLength = ko.utils.unwrapObservable(allBindingsAccessor().trimLength) || defaultMaxLength
            if (maxLength < minLength)
                maxLength = minLength
            var text = ''
            if (untrimmedText !== null)
                text = untrimmedText.length > maxLength ? untrimmedText.substring(0, maxLength - 1) + '...' : untrimmedText;
            return text;
        });
        ko.applyBindingsToNode(element, {
            text: trimmedText
        }, viewModel);

        return {
            controlsDescendantBindings: true
        };
    }
};

/**
 * limit the text to the specified length and you can specify the attribute type
 *
 * <p data-bind="limitedText: { text: myLongText, length: 15, attr:'html' }"></p>
 */
ko.bindingHandlers.limitedText = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        var options = ko.unwrap(valueAccessor()),
            attr = ko.unwrap(options.attr || "text");

        if (attr === "html")
            ko.bindingHandlers.html.init(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext);
    },
    update: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        var options = ko.unwrap(valueAccessor()),
            text = ko.unwrap(options.text),
            length = ko.unwrap(options.length),
            suffix = ko.unwrap(options.suffix || "..."),
            escapeCR = ko.unwrap(options.escapeCR || false),
            attr = ko.unwrap(options.attr || "text");

        var result = text;
        if (text) {
            if (text.length > length)
                result = result.substr(0, length) + suffix;
            if (escapeCR) {
                result = result.replace(new RegExp("\n", "g"), " ").replace(new RegExp("\r", "g"), " ");
            }
        }

        if (attr === "text")
            ko.bindingHandlers.text.update(element, createAccessor(result), allBindingsAccessor, viewModel, bindingContext);
        else if (attr === "html")
            ko.bindingHandlers.html.update(element, createAccessor(result), allBindingsAccessor, viewModel, bindingContext);
        else {
            var obj = {};
            obj[attr] = result;
            ko.bindingHandlers.attr.update(element, createAccessor(obj), allBindingsAccessor, viewModel, bindingContext);
        }
    }
};