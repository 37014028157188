/**
 *  <span data-bind="price: percent, currency:'%'">20%</span>
 *  <span data-bind="price: dollar, currency:'$'">20$</span>
 *  <span data-bind="price: amount>20€</span>
 *  <span data-bind="price: 0, empty:'nessuno'>Nessuno</span>
 *
 */
ko.bindingHandlers.price = {
  init: function (element, valueAccessor, allBindingsAccessor) {
    var value = ko.utils.unwrapObservable(valueAccessor())

    var currency = '€'
      var empty = '-'
    if (allBindingsAccessor.has('currency')) {
      currency = allBindingsAccessor.get('currency')
    }
      if (allBindingsAccessor.has('empty')) {
          empty = allBindingsAccessor.get('empty')
      }

    $(element).html((currency === '%' ? value : parseFloat(value).toFixed(2)) + currency)
  },
  update: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
    var value = ko.utils.unwrapObservable(valueAccessor())

    var currency = '€'
      var empty = '-'

    if (allBindingsAccessor.has('currency')) {
      currency = allBindingsAccessor.get('currency')
    }
      if (allBindingsAccessor.has('empty')) {
          empty = allBindingsAccessor.get('empty')
      }

    if (value === '-' || value === null || value === undefined || value === '')
        $(element).html(empty)
    else {
      $(element).html((currency === '%' ? value : parseFloat(value).toFixed(2)) + currency)
    }
  }

}
