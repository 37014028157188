ko.bindingHandlers.checkedRadioToBool = {
    init: function (element, valueAccessor, allBindingsAccessor) {
        var observable = valueAccessor(),
            interceptor = ko.computed({
                read: function () {
                    if (observable() !== null) {
                        if (Number(observable()) === 0)
                            return "false";
                        if (Number(observable()) === 1)
                            return "true";
                        return observable().toString();
                    }
                },
                write: function (newValue) {
                    observable(newValue === "true");
                },
                owner: this
            });
        ko.applyBindingsToNode(element, {checked: interceptor});
    }
};