/*
 *
 <div data-bind="progressBar: { text : textBar, percentage: percentValue, limit: maxValue}"></div>
 *
/*
.progressBar {
    position: relative;
    width: 95%;
    height: 24px;
    margin: 10px auto;
    border: 3px solid #66cc66;
    border-radius: 5px;
    overflow: hidden;
}

.progressBar div {
    position: relative;
    height: 100%;
    background-color: #66cc66;
}

.progressBar .progressText {
    position: absolute;
    margin: 0 auto;
    color: #FFFFFF;
    top: 0;
    left: 10px;
    right: 0;
    background-color: transparent;
}
 */
ko.bindingHandlers.progressBar = {
    init: function (element) {
        return {controlsDescendantBindings: true};
    },
    update: function (element, valueAccessor, bindingContext) {
        var options = ko.unwrap(valueAccessor());

        var percentage = options.percentage();
        var text = options.text();
        var limit = options.limit();

        var width = percentage + "%";

        var textPrint = '';
        if (!text)
            textPrint = width.toString();

        if (text && limit)
            textPrint = text + ' / ' + limit.toString();

        $(element).addClass("progressBar");

        ko.applyBindingsToNode(element, {
            html: '<div data-bind="style: { width: \'' + width + '\' }"></div><div class="progressText" data-bind="text: \'' + textPrint + '\' "></div>'
        });

        ko.applyBindingsToDescendants(bindingContext, element);
    }
};