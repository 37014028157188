ko.bindingHandlers.start = {};
ko.bindingHandlers.stop = {};
ko.bindingHandlers.hideField = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel) {
        var hideText = ko.computed(function () {
            var unhideText = ko.utils.unwrapObservable(valueAccessor());
            var defaultStart = 6;
            var defaultStop = 6;

            var start = ko.utils.unwrapObservable(allBindingsAccessor().start) || defaultStart;
            var stop = ko.utils.unwrapObservable(allBindingsAccessor().stop) || defaultStop;

            if (stop < start) start = stop;

            var text = "";
            if (unhideText != null) {
                if (unhideText.lastIndexOf("@") > 0) {
                    var name = unhideText.substring(0, unhideText.lastIndexOf("@"));
                    var domain = unhideText.substring(unhideText.lastIndexOf("@") + 1);
                    var textName = name.length > start ? name.substring(0, start) + '...' : name.substring(0, start % 2);
                    var textDomain = domain.length > stop ? '...' + domain.substring(domain.length, domain.length - stop) : '...' + domain.substring(domain.length, domain.length - (stop % 2));
                    text = textName + '@' + textDomain;
                } else {
                    var textStart = unhideText.length > start ? unhideText.substring(0, start) + '...' : unhideText.substring(0, start % 2);
                    var textEnd = unhideText.length > stop ? '...' + unhideText.substring(unhideText.length, unhideText.length - stop) : '...' + unhideText.substring(unhideText.length, unhideText.length - (stop % 2));
                    text = textStart + textEnd;
                }
            }
            return text;
        });
        ko.applyBindingsToNode(element, {
            text: hideText
        }, viewModel);

        return {
            controlsDescendantBindings: true
        };
    }
};
