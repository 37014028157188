/*
 <progress-bar params="progress:currentValue,min: minValue,max: maxValue, tooltip: textTooltip, percent: false, type:success"></progress-bar>
*/
ko.components.register('progress-bar', {
    viewModel: function (params) {
        var self = this;
        self.currentValue = params.progress;
        self.tooltip = params.tooltip;
        self.maxValue = params.max ? params.max : 100;
        self.minValue = params.min ? params.min : 0;
        self.showPercent = params.percent ? params.percent : false;

        self.type = params.type ? params.type : "danger";

        self.colorBar = ko.computed(function () {
            return "progress-bar-" + self.type;
        });

        self.percent = (ko.utils.unwrapObservable(self.currentValue) * 100 / ko.utils.unwrapObservable(self.maxValue));

        self.progressPercent = ko.computed(function () {
            return '' + (self.percent > 100 ? 100 : self.percent) + '%';
        });
        self.textPercent = ko.computed(function () {
            if (!self.showPercent) {
                return '' + ko.utils.unwrapObservable(self.currentValue);
            }

            var percent = Math.round(self.percent);
            return '' + percent + '%';

        })
    },
    template:
        '<div class="progress"><div data-bind="attr:{\'aria-valuenow\':currentValue,\'aria-valuemin\':minValue,\'aria-valuemax\':maxValue}, style:{width:progressPercent}, text:textPercent,tooltip:{title:tooltip,placement:\'top\'}, class:colorBar" class="progress-bar " role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="min-width: 2em;"></div></div>'
});