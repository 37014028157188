ko.bindingHandlers.startDateTime = {};
ko.bindingHandlers.durationMinutes = {};
ko.bindingHandlers.dataTimer = {

    init: function (element, valueAccessor, allBindingsAccessor, viewModel) {
        var currentTime = moment().unix();
        this.durationMinutes = allBindingsAccessor().durationMinutes ? allBindingsAccessor().durationMinutes : 10;
        var eventTime = moment(allBindingsAccessor().startDateTime).add(this.durationMinutes, 'm').unix();
        var diffTime = eventTime + -currentTime;
        var duration = moment.duration(diffTime * 1000, 'milliseconds');
        $(element).text(moment(duration.asMilliseconds()).format('hh:mm:ss'));
    },
    update: function (element, valueAccessor, allBindingsAccessor, viewModel) {
        // retrieve the value from the span

        var eventTime = moment(allBindingsAccessor().startDateTime).add(this.durationMinutes, 'm').unix(),
            currentTime = moment().unix(),
            diffTime = eventTime + -currentTime;

        dateCountdown(diffTime, element);

    },

    countDown: ko.observable()
};


