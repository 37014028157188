var configInitDatatables = {}
configInitDatatables.deferRender = true
configInitDatatables.paging = true
configInitDatatables.responsive = true
configInitDatatables.bRetrieve = true
configInitDatatables.bLengthChange = false
configInitDatatables.iDisplayLength = 10
configInitDatatables.pagingType = 'simple'
configInitDatatables.processing = true
configInitDatatables.columnDefs = []
configInitDatatables.language = {
  'sEmptyTable': '',
  'sInfo': 'Vista da _START_ a _END_ di _TOTAL_ elementi',
  'sInfoEmpty': 'Vista da 0 a 0 di 0 elementi',
  'sInfoFiltered': '(filtrati da _MAX_ elementi totali)',
  'sInfoPostFix': '',
  'sInfoThousands': '.',
  'sLengthMenu': 'Visualizza _MENU_ elementi',
  'sLoadingRecords': 'Caricamento...',
  'sProcessing': '<div id="gusty-spinner"><div id="gusty-loader"></div></div>',
  'sSearch': 'Cerca:',
  'sZeroRecords': '',
  'oPaginate': {
    'sFirst': 'Inizio',
    'sPrevious': 'Precedente',
    'sNext': 'Successivo',
    'sLast': 'Fine'
  },
  'oAria': {
    'sSortAscending': ': attiva per ordinare la colonna in ordine crescente',
    'sSortDescending': ': attiva per ordinare la colonna in ordine decrescente'
  }
}
if (configInitDatatables.responsive) {
  configInitDatatables.responsive = { details: false }
}

var loaded = false

ko.bindingHandlers.dataTables = {
  page: 0,
  init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
    var binding = ko.utils.unwrapObservable(valueAccessor())
    ko.unwrap(binding.data)

    var options = $.extend(true, configInitDatatables, binding.options)

    if (options.paging) {
      binding.data.subscribe(function (changes) {
        var table = $(element).closest('table').DataTable()
        ko.bindingHandlers.dataTables.page = table.page()
        table.destroy()
      }, null, 'arrayChange')
    }

    if (binding.url && !options.serverSide) {
      getData(binding.url)
        .then(function (response) {
          if (response.success) {
            var data = []
            var items = response.data
            if (response.data.data)
              items = response.data.data
            ko.utils.arrayForEach(items, function (item) {
              if (binding.processor)
                data.push(binding.processor.process(item))
              else
                data.push(ko.mapping.fromJS(item))
            })
            binding.data(data)
          }
          $(element).closest('table').DataTable().processing(false)
          if (options.language.sZeroRecords !== '')
            $('.dataTables_empty').html(options.language.sZeroRecords)
          else
            $('.dataTables_empty').html('Non ci sono dati da visualizzare')
          loaded = true
        })
        .catch(function (response) {
          var data = []
          binding.data(data)
          $(element).closest('table').DataTable().processing(false)
          if (options.language.sZeroRecords !== '')
            $('.dataTables_empty').html(options.language.sZeroRecords)
          else
            $('.dataTables_empty').html('Non ci sono dati da visualizzare')
        })
    }

    var nodes = Array.prototype.slice.call(element.childNodes, 0)
    ko.utils.arrayForEach(nodes, function (node) {
      if (node && node.nodeType !== 1) {
        node.parentNode.removeChild(node)
      }
    })

    return ko.bindingHandlers.foreach.init(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext)
  },
  update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
    var binding = ko.utils.unwrapObservable(valueAccessor()),
      key = 'DataTablesForEach_Initialized'
    ko.unwrap(binding.data)

    var options = $.extend(true, configInitDatatables, binding.options)

    var table
    if (!options.paging) {
      table = $(element).closest('table').DataTable()
      table.destroy()
    }

    ko.bindingHandlers.foreach.update(element, valueAccessor, allBindings, viewModel, bindingContext)

    table = $(element).closest('table').DataTable(options)

    if (!loaded)
      table.processing(true)

    if (options.paging) {
      if (table.page.info().pages - ko.bindingHandlers.dataTables.page === 0) {
        table.page(--ko.bindingHandlers.dataTables.page).draw(false)
      } else {
        table.page(ko.bindingHandlers.dataTables.page).draw(false)
      }
    }

    if (!ko.utils.domData.get(element, key) && (binding.data || binding.length)) {
      ko.utils.domData.set(element, key, true)
    }

    return {
      controlsDescendantBindings: true
    }
  }
}

function getData (url) {
  return rest('GET', url)
}