ko.utils.uniqueId = (function () {

    var prefixesCounts = {
        'ks-unique-': 0
    };

    return function (prefix) {
        prefix = prefix || 'ks-unique-';

        if (!prefixesCounts[prefix]) {
            prefixesCounts[prefix] = 0;
        }

        return prefix + prefixesCounts[prefix]++;
    };
})();
ko.utils.unwrapProperties = ko.toJS;

// inspired by http://www.knockmeout.net/2011/10/ko-13-preview-part-3-template-sources.html
(function () {
    // storage of string templates for all instances of stringTemplateEngine
    var templates = {};

    templates['alert'] = '<div class="alert fade in" data-bind="css: type, template: innerTemplate">\n' +
        '</div>';

    templates['alertInner'] = '<button class="close" data-dismiss="alert" aria-hidden="true">&times;</button>\n' +
        '<p data-bind="text: message"></p>';

    templates['carousel'] = '<!-- ko template: indicatorsTemplate -->\n' +
        '<!-- /ko -->\n' +
        '<div class="carousel-inner">\n' +
        '    <!-- ko foreach: items -->\n' +
        '    <div class="item" data-bind="with: $parent.converter($data), css: { active: $index() == 0 }">\n' +
        '        <img data-bind="attr: { src: src, alt: alt }">\n' +
        '        <div class="container">\n' +
        '            <div class="carousel-caption">\n' +
        '                <!-- ko template: { name: $parents[1].itemTemplateName, data: $data, templateEngine: $parents[1].templateEngine,\n' +
        '                    afterRender: $parents[1].afterRender, afterAdd: $parents[1].afterAdd, beforeRemove: $parents[1].beforeRemove } -->\n' +
        '                <!-- /ko -->\n' +
        '            </div>\n' +
        '        </div>\n' +
        '    </div>\n' +
        '    <!-- /ko -->\n' +
        '</div>\n' +
        '<!-- ko template: controlsTemplate -->\n' +
        '<!-- /ko -->\n' +
        '';

    templates['carouselContent'] = '<div data-bind="text: content"></div>';

    templates['carouselControls'] = '<a class="left carousel-control" data-bind="attr: { href: id }" data-slide="prev">\n' +
        '    <span class="icon-prev"></span>\n' +
        '</a>\n' +
        '<a class="right carousel-control" data-bind="attr: { href: id }" data-slide="next">\n' +
        '    <span class="icon-next"></span>\n' +
        '</a>';

    templates['carouselIndicators'] = '<ol class="carousel-indicators" data-bind="foreach: items">\n' +
        '    <li data-bind="attr: { \'data-target\': $parent.id, \'data-slide-to\': $index }, css: { active: $index() == 0 }"></li>\n' +
        '</ol>\n' +
        '';

    templates['modal'] = '<div class="modal-dialog" data-bind="css: dialogCss">\n' +
        '    <div class="modal-content">\n' +
        '        <div class="modal-header" data-bind="template: headerTemplate">\n' +
        '        </div>\n' +
        '\n' +
        '        <div class="modal-body" data-bind="template: bodyTemplate">\n' +
        '        </div>\n' +
        '        <!-- ko if: footerTemplate -->\n' +
        '        <div class="modal-footer" data-bind="template: footerTemplate">\n' +
        '        </div>\n' +
        '        <!-- /ko -->\n' +
        '    </div>\n' +
        '</div>';

    templates['modalBody'] = '<div data-bind="html: content">\n' +
        '</div>';

    templates['modalFooter'] = '<!-- ko if: $data.action -->\n' +
        '<a href="#" class="btn btn-primary" data-bind="click: action, html: primaryLabel"></a>\n' +
        '<!-- /ko -->\n' +
        '<a href="#" class="btn btn-default" data-bind="html: closeLabel" data-dismiss="modal"></a>';

    templates['modalHeader'] = '<button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>\n' +
        '<h3 data-bind="text: label"></h3>\n' +
        '';

    templates['pager'] = '<ul class="pager">\n' +
        '    <li data-bind="css: { previous: isAligned, disabled: isBackDisabled }">\n' +
        '        <a href="#" data-bind="with: text, click: goBack">\n' +
        '            <span data-bind="html: back"></span>\n' +
        '        </a>\n' +
        '    </li>\n' +
        '    <li data-bind="css: { next: isAligned, disabled: isForwardDisabled }">\n' +
        '        <a href="#" data-bind="with: text, click: goForward">\n' +
        '            <span data-bind="html: forward"></span>\n' +
        '        </a>\n' +
        '    </li>\n' +
        '</ul>\n' +
        '';

    templates['pagination'] = '<ul class="pagination">\n' +
        '    <!-- ko if: boundary -->\n' +
        '    <li class="ks-boundary-first" data-bind="css: { disabled: isBackDisabled }">\n' +
        '        <a href="#" data-bind="with: text, click: goFirst">\n' +
        '            <span data-bind="html: first"></span>\n' +
        '        </a>\n' +
        '    </li>\n' +
        '    <!-- /ko -->\n' +
        '    \n' +
        '    <!-- ko if: directions -->\n' +
        '    <li class="ks-direction-back" data-bind="css: { disabled: isBackDisabled }">\n' +
        '        <a href="#" data-bind="with: text, click: goBack">\n' +
        '            <span data-bind="html: back"></span>\n' +
        '        </a>\n' +
        '    </li>\n' +
        '    <!-- /ko -->\n' +
        '\n' +
        '    <!-- ko foreach: pages -->\n' +
        '    <li data-bind="css: { active: isActive }">\n' +
        '        <a href="#" data-bind="text: text, click: $parent.selectPage"></a>\n' +
        '    </li>\n' +
        '    <!-- /ko -->\n' +
        '    \n' +
        '    <!-- ko if: directions -->\n' +
        '    <li class="ks-direction-forward" data-bind="css: { disabled: isForwardDisabled }">\n' +
        '        <a href="#" data-bind="with: text, click: goForward">\n' +
        '            <span data-bind="html: forward"></span>\n' +
        '        </a>\n' +
        '    </li>\n' +
        '    <!-- /ko -->\n' +
        '\n' +
        '    <!-- ko if: boundary -->\n' +
        '    <li class="ks-boundary-last" data-bind="css: { disabled: isForwardDisabled }">\n' +
        '        <a href="#" data-bind="with: text, click: goLast">\n' +
        '            <span data-bind="html: last"></span>\n' +
        '        </a>\n' +
        '    </li>\n' +
        '    <!-- /ko -->\n' +
        '</ul>';

    templates['progress'] = '<!-- ko foreach: $data -->\n' +
        '<div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"\n' +
        '    data-bind="style: { width: barWidth }, attr: { \'aria-valuenow\': value }, css: { active: animated, \'progress-bar-striped\': striped }, \'class\': barType">\n' +
        '    <span data-bind="css: { \'sr-only\': textHidden }">\n' +
        '        <span data-bind="text: value"></span>% <span data-bind="text: text"></span>\n' +
        '    </span>\n' +
        '</div>\n' +
        '<!-- /ko -->';


    // create new template source to provide storing string templates in storage
    ko.templateSources.stringTemplate = function (template) {
        this.templateName = template;

        this.data = function (key, value) {
            templates.data = templates.data || {};
            templates.data[this.templateName] = templates.data[this.templateName] || {};

            if (arguments.length === 1) {
                return templates.data[this.templateName][key];
            }

            templates.data[this.templateName][key] = value;
        };

        this.text = function (value) {
            if (arguments.length === 0) {
                return templates[this.templateName];
            }

            templates[this.templateName] = value;
        };
    };

    // create modified template engine, which uses new string template source
    ko.stringTemplateEngine = function () {
        this.allowTemplateRewriting = false;
    };

    ko.stringTemplateEngine.prototype = new ko.nativeTemplateEngine();
    ko.stringTemplateEngine.prototype.constructor = ko.stringTemplateEngine;

    ko.stringTemplateEngine.prototype.makeTemplateSource = function (template) {
        return new ko.templateSources.stringTemplate(template);
    };

    ko.stringTemplateEngine.prototype.getTemplate = function (name) {
        return templates[name];
    };

    ko.stringTemplateEngine.prototype.addTemplate = function (name, template) {
        if (arguments.length < 2) {
            throw new Error('template is not provided');
        }

        templates[name] = template;
    };

    ko.stringTemplateEngine.prototype.removeTemplate = function (name) {
        if (!name) {
            throw new Error('template name is not provided');
        }

        delete templates[name];
    };

    ko.stringTemplateEngine.prototype.isTemplateExist = function (name) {
        return !!templates[name];
    };

    ko.stringTemplateEngine.instance = new ko.stringTemplateEngine();
})();