ko.components.register('button-tg', {
    viewModel: function (params) {
        var self = this;
        self.text = params.text;
        self.tooltip = params.tooltip;
        self.loading = params.loading?params.loading:ko.observable(false);
        self.action = params.action;
        self.btnCss = params.btnCss;
        self.icon = params.icon;

    },
    template: "    <button type=\"button\" class=\"mg-b-xs btn btn-xs \"\n" +
        "            data-bind=\"click: action,tooltip: {'title': tooltip}, class:btnCss\">\n" +
        "        <!-- ko if: icon-->\n" +
        "        <!-- ko if: !loading()-->\n" +
        "        <i class=\"fa\" data-bind=\"css:icon\" aria-hidden=\"true\"></i>\n" +
        "        <!-- /ko -->\n" +
        "        <!-- ko if: loading -->\n" +
        "        <i class=\"fa fa-spinner fa-spin\"></i>\n" +
        "        <!-- /ko -->\n" +
        "        <!-- /ko -->\n" +
        "        <!-- ko if: text-->\n" +
        "        <!-- ko if: !loading()-->\n" +
        "        <span data-bind=\"text:text\"></span>\n" +
        "        <!-- /ko -->\n" +
        "        <!-- ko if: loading-->\n" +
        "        Attendi\n" +
        "        <!-- /ko -->\n" +
        "        <!-- /ko -->\n" +
        "    </button>"
});
