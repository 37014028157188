// inspired by http://www.knockmeout.net/2011/10/ko-13-preview-part-3-template-sources.html
(function () {
    // storage of string templates for all instances of stringTemplateEngine
    var templates = {};

    templates['modal'] = '<div class="modal-dialog" data-bind="css: dialogCss">\n' +
        '        <!-- ko if: headerTemplate -->\n' +
        '        <div class="modal-header" data-bind="template: headerTemplate">\n' +
        '        </div>\n' +
        '        <!-- /ko -->\n' +
        '        <div class="modal-body" data-bind="template: bodyTemplate">\n' +
        '        </div>\n' +
        '        <!-- ko if: footerTemplate -->\n' +
        '        <div class="modal-footer" data-bind="template: footerTemplate">\n' +
        '        </div>\n' +
        '        <!-- /ko -->\n' +
        '</div>';

    templates['modalBody'] = '<div data-bind="html: content">\n' +
        '</div>';

    templates['modalFooter'] = '<!-- ko if: $data.action -->\n' +
        '<a href="#" class="btn btn-primary" data-bind="click: action, html: primaryLabel"></a>\n' +
        '<!-- /ko -->\n' +
        '<a href="#" class="btn btn-default" data-bind="html: closeLabel" data-dismiss="modal"></a>';

    templates['modalHeader'] = '<button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>\n' +
        '<h3 data-bind="text: label"></h3>\n' +
        '';


    // create new template source to provide storing string templates in storage
    ko.templateSources.stringTemplate = function (template) {
        this.templateName = template;

        this.data = function (key, value) {
            templates.data = templates.data || {};
            templates.data[this.templateName] = templates.data[this.templateName] || {};

            if (arguments.length === 1) {
                return templates.data[this.templateName][key];
            }

            templates.data[this.templateName][key] = value;
        };

        this.text = function (value) {
            if (arguments.length === 0) {
                return templates[this.templateName];
            }

            templates[this.templateName] = value;
        };
    };

    // create modified template engine, which uses new string template source
    ko.stringTemplateEngine = function () {
        this.allowTemplateRewriting = false;
    };

    ko.stringTemplateEngine.prototype = new ko.nativeTemplateEngine();
    ko.stringTemplateEngine.prototype.constructor = ko.stringTemplateEngine;

    ko.stringTemplateEngine.prototype.makeTemplateSource = function (template) {
        return new ko.templateSources.stringTemplate(template);
    };

    ko.stringTemplateEngine.prototype.getTemplate = function (name) {
        return templates[name];
    };

    ko.stringTemplateEngine.prototype.addTemplate = function (name, template) {
        if (arguments.length < 2) {
            throw new Error('template is not provided');
        }

        templates[name] = template;
    };

    ko.stringTemplateEngine.prototype.removeTemplate = function (name) {
        if (!name) {
            throw new Error('template name is not provided');
        }

        delete templates[name];
    };

    ko.stringTemplateEngine.prototype.isTemplateExist = function (name) {
        return !!templates[name];
    };

    ko.stringTemplateEngine.instance = new ko.stringTemplateEngine();
})();

ko.bindingHandlers.modalTg = {
    defaults: {
        css: 'modal fade',
        dialogCss: '',
        attributes: {
            role: 'dialog'
        },

        headerTemplate: {
            name: 'modalHeader',
            templateEngine: ko.stringTemplateEngine.instance
        },

        bodyTemplate: {
            name: 'modalBody',
            templateEngine: ko.stringTemplateEngine.instance
        },

        footerTemplate: {
            name: 'modalFooter',
            templateEngine: ko.stringTemplateEngine.instance,
            data: {
                closeLabel: 'Close',
                primaryLabel: 'Ok'
            }
        }
    },

    init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        var $element = $(element),
            value = valueAccessor(),
            defaults = ko.bindingHandlers.modal.defaults,
            options = ko.utils.extend({show: $element.data().show || false}, ko.utils.unwrapProperties(value.options)),
            extendDefaults = function (defs, val) {
                var extended = {
                    name: defs.name,
                    data: defs.data,
                };

                // reassign to not overwrite default content of data property
                extended = $.extend(true, {}, extended, val);
                if (!val || !val.name) {
                    extended.templateEngine = defs.templateEngine;
                }

                return extended;
            };

        if (!value.body) {
            throw new Error('body options are required for modal binding.');
        }

        // fix for not working escape button
        if (options.keyboard || typeof options.keyboard === 'undefined') {
            $element.attr('tabindex', -1);
        }

        var model = {
            dialogCss: value.dialogCss || defaults.dialogCss,
            headerTemplate: value.header ? extendDefaults(defaults.headerTemplate, ko.unwrap(value.header)) : null,
            bodyTemplate: extendDefaults(defaults.bodyTemplate, ko.unwrap(value.body)),
            footerTemplate: value.footer ? extendDefaults(defaults.footerTemplate, ko.unwrap(value.footer)) : null
        };

        ko.renderTemplate('modal', bindingContext.createChildContext(model), {templateEngine: ko.stringTemplateEngine.instance}, element);

        $element.addClass(defaults.css).attr(defaults.attributes);
        $element.modal(options);

        $element.on('shown.bs.modal', function () {
            if (typeof value.visible !== 'undefined' && typeof value.visible === 'function' && !ko.isComputed(value.visible)) {
                value.visible(true);
            }

            $(this).find("[autofocus]:first").focus();
        });

        if (typeof value.visible !== 'undefined' && typeof value.visible === 'function' && !ko.isComputed(value.visible)) {
            $element.on('hidden.bs.modal', function () {
                value.visible(false);
            });

            // if we need to show modal after initialization, we need also set visible property to true
            if (options.show) {
                value.visible(true);
            }
        }

        return {controlsDescendantBindings: true};
    },

    update: function (element, valueAccessor) {
        var value = valueAccessor();

        if (typeof value.visible !== 'undefined') {
            $(element).modal(!ko.unwrap(value.visible) ? 'hide' : 'show');
        }
    }
};